import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

const Mailto = ({email, as, children}) => {
    const Cmp = as;
    const [value, setValue] = useState(null);

    useEffect(() => {
        setValue(email);
    }, [email]);

    if (!value) {
        return null;
    }

    return (
        <Cmp href={`mailto:${value}`}>{children}</Cmp>
    )
};

Mailto.propTypes = {
    email: PropTypes.string.isRequired,
    as: PropTypes.elementType,
    children: PropTypes.node,
}

Mailto.defaultProps = {
    as: 'a'
}

export default Mailto;
