import React from 'react';
import {Helmet} from 'react-helmet';
import {Col, Row} from 'react-bootstrap';
import {StaticImage} from 'gatsby-plugin-image';
import {graphql, useStaticQuery} from 'gatsby';

import Logo from '../components/Logo';
import QuestionsAndAnswers from '../components/QuestionsAndAnswers';
import RegionalCard from '../components/RegionalCard';
import Block from '../components/Block';
import InstagramLink from '../components/InstagramLink';
import ContactForm from '../components/form/ContactForm';
import FacebookLink from '../components/FacebookLink';
import SocialMediaBlock from '../components/SocialMediaBlock';
import ImpressionBlock from '../components/ImpressionBlock';
import YouTubeVideo from '../components/YouTubeVideo';

const ClubsPage = () => {
    const {allRegionalsJson: {nodes: regionals}} = useStaticQuery(graphql`
        query {
            allRegionalsJson {
                nodes {
                    id
                    title
                    name
                    email
                    website
                }
            }
        }
    `);

    return (
        <>
            <Helmet title="Teams & Vereine"/>
            <Logo title="Teams & Vereine"/>

            <Block variant="primary" image={(
                <StaticImage src="../images/teams/huddle.jpg" alt="Teams & Vereine" aspectRatio={1} width={400}/>
            )}>
                <p className="text-white">Ihr seid eine Hockeymannschaft, die im Winter Eishockey spielt und im Sommer
                    gerne mit dem Ball zockt? Vielleicht habt ihr auch ein paar Kids, die ihr trainiert oder die einfach
                    bei euch mitspielen? Und ihr habt Lust, euch als Team weiterzuentwickeln? Euch mit anderen
                    Mannschaften zu messen? Dann wird es Zeit, Teil der deutschlandweiten Skaterhockey-Familie zu
                    werden. Nehmt Kontakt zu eurem naheliegenden Verband auf und holt euch direkt Infos, wie ihr so
                    schnell und einfach wie möglich an einem geregelten Spielbetrieb teilnehmen könnt. Oder meldet euch
                    direkt zu einer unserer regionalen Rookie-Ligen an! Macht den ersten Schritt! Ihr wärt nicht die
                    Ersten, die es vom Parkplatz in die Bundesliga geschafft hätten.</p>
            </Block>

            <Block variant="light">
                <h2>Kaarst und Deggendorf - die Geschichte zweier Vorzeigevereine</h2>
                <p>Die Wege der Deggendorf Pflanz und der Crash Eagles Kaarst ins Skaterhockey stehen exemplarisch
                    für viele erfolgreiche Mannschaften und Vereine, die sich auf gemacht haben, ihrer Sportart in ihrer
                    Region eine Struktur zu geben.</p>
                <YouTubeVideo videoId="X6RL8CeEdrg"/>
            </Block>

            <Block>
                <h2>Kontakte in den Bundesländern</h2>
                <p>In vielen Bundesländern gibt es sog. Landesfachwart:innen, die sich um die regionalen Belange des
                    Skaterhockey kümmern. Nimm einfach Kontakt mit dem zuständigen Landesfachwart oder der zuständigen
                    Landesfachwartin auf, wenn du mehr über Skaterhockey in deinem Bundesland wissen willst. Sollte für
                    dein Bundesland kein:e Landesfachwart:in aufgeführt sein, kannst du gerne auch das Kontaktformular
                    verwenden und uns kontaktieren. Wir leiten deine Anfrage dann an den:die passende:n
                    Ansprechpartner:in weiter.</p>
                <Row xs={1} md={3} className="g-1 gx-md-2">
                    {regionals.map((regional, i) => (
                        <Col key={i}>
                            <RegionalCard title={regional.title} name={regional.name}
                                          email={regional.email} website={regional.website}/>
                        </Col>
                    ))}
                </Row>
            </Block>

            <Block variant="bayern">
                <p className="text-white">Du bist aus Bayern? Dann könnte das Folgende für dich interessant sein.</p>

                <h2 className="text-white">Neu in der Saison 2022: Rookie-Liga für Einsteiger</h2>

                <Row>
                    <Col md={4}>
                        <StaticImage src="../images/rookie_liga_left.jpg"
                                     alt="Rookie-Liga für Einsteiger in Bayern" aspectRatio={1}
                                     width={400} imgClassName="img-fluid" className="h-100"/>
                    </Col>
                    <Col md={8} className="text-white">
                        <p>Erstmals bietet der BRIV eine sogenannte Rookie-Liga unterhalb der Landesliga an. Zielgruppe
                            sind Teams, die entweder keinem Verein zugehörig sind oder innerhalb eines Vereins bislang
                            noch nicht am Spielbetrieb teilgenommen haben. Jede gemeldete Mannschaft richtet – sofern
                            sie über eine Spielstätte verfügt – einen Heimspieltag aus, an dem mindestens drei Teams
                            gegeneinander spielen. Zeitnehmer:innen und Schiedsrichter:innen stellt der BRIV. Die
                            Rookie-Liga richtet sich schwerpunktmäßig an gänzlich neue Teams bzw. Vereine sowie an
                            Wiedereinsteiger. Nicht teilnahmeberechtigt sind alle Mannschaften, die 2019, 2020 und 2021
                            am BRIV- bzw. ISHD-Spielbetrieb teilgenommen haben.</p>

                        <p>Eure Vorteile:</p>

                        <ul>
                            <li>Spielbetrieb ohne große Hürden: keine Pässe, kein Verein nötig</li>
                            <li>BRIV übernimmt Schiedsrichter:innen und Zeitnehmer:innen</li>
                            <li>Teilnahme auch ohne Spielstätte möglich</li>
                            <li>Geringe Startgebühr: Nur 250 Euro pro Team und Saison</li>
                            <li>Spieltag in Turnierform mit mindestens drei Teams</li>
                        </ul>

                        <a className="btn btn-outline-bayern" href="https://www.briv-online.de" target="_blank"
                           rel="noreferrer">Infos und Anmeldung auf www.briv-online.de</a>
                    </Col>
                </Row>
            </Block>

            <Block>
                <h2 id="contact">Kontakt</h2>
                <p>Du hast Interesse an unserem Sport und möchtest mehr erfahren? Sprich uns
                    bei <FacebookLink/> oder <InstagramLink/> an oder nutze das Kontaktformular!</p>
                <ContactForm topic="teams"/>
            </Block>

            <Block variant="light">
                <h2>Skaterhockey FAQ</h2>
                <p>Ihr wollt euch als Team mit anderen Mannschaften messen und am offiziellen Skaterhockey-Ligabetrieb
                    teilnehmen? Dann habt ihr sicher ein paar Fragen. Wir die Antworten.</p>
                <QuestionsAndAnswers questions={[{
                    question: 'Wie können wir uns für den Ligenbetrieb anmelden?',
                    answer: 'Wir empfehlen euch, im ersten Schritt direkt mit dem:der für euch zuständigen Landesfachwart:in für Skaterhockey in Kontakt zu treten. Er bzw. sie hat die direkten Informationen, die für euer jeweiliges Bundesland relevant sind. Grundsätzlich müsst ihr zunächst einen Verein gründen und dann Mitglied in eurem Landesrollsportverband werden. Dann erhaltet ihr von eurem:eurer Landesfachwart:in jeweils im letzten Quartal eines Jahres Anmeldeunterlagen zugeschickt, die ihr entsprechend ausfüllt. Und los geht\'s.'
                }, {
                    question: 'Welche Voraussetzungen müssen wir erfüllen, damit eine Meldung Sinn macht?',
                    answer: 'Zunächst solltet ihr über einige motivierte Spieler:innen verfügen, die Lust haben, sich mit anderen Teams zu messen. Erfahrungsgemäß ist es immer gut, mindestens zwölf Spieler:innen und zwei Torhüter:innen zu haben. Hinzu kommt eine Trainings- und Spielstätte, die ihr zumindest anmieten könnt. Wenn ihr dann auch noch einheitliche Trikots und für alle Spieler:innen und die entsprechende Schutzausrüstung parat habt, kann\'s an sich schon los gehen.'
                }, {
                    question: 'Welche Anforderungen gibt es an die Spielstätte?',
                    answer: 'Die Spielregeln sehen eine Spielstätte zwischen 15 x 30m und 30 x 60m vor, wobei für internationale Spiele 20 x 40m empfohlen wird. Das Spielfeld braucht eine Bande (zwischen 0,80 m und 1,22 m) mit abgerundeten Ecken; auch Wände einer Sporthalle können als Bande dienen. Die Oberfläche muss flach und glatt sein (z.B. Holz, Fliesen, Parkett, Asphalt, Kunststoff etc.); es kann indoor wie outdoor (ohne Hallendach) gespielt werden.'
                }, {
                    question: 'Was müssen wir sonst noch organisieren, bevor es los geht?',
                    answer: 'Ihr müsst für alle eure Spieler:innen beim Verband einen Spielerpass beantragen; zudem braucht ihr pro Spiel zwei ausgebildete Zeitnehmer:innen, die die Spielzeituhr bedienen und über jedes Spiel einen Spielberichtsbogen anfertigen. Dazu bietet der Verband kompakte Kurzausbildungen an. In dem für euch zweiten Jahr müsst ihr dann auch Schiedsrichter:innen vorhalten. Wie viele ist von Bundesland zu Bundesland verschieden. Auch für Schiedsrichter:innen gibt es Ausbildungen und jährliche Weiterbildungen.'
                }, {
                    question: 'Was müssen wir tun, wenn wir eine Nachwuchsmannschaft melden wollen?',
                    answer: 'Im Prinzip das gleiche. Die meisten Landesverbände bieten in verschiedenen Altersklassen (Bambini U10, Schüler U13, Jugend U16, Junioren U19) Turnier- oder Spielrunden an. Es gelten dieselben Rahmenbedingungen wie für Erwachsene. Natürlich ist es speziell bei Nachwuchsteams wichtig, die Eltern mit ins Boot zu holen und für Heim- und Auswärtsspiele einzubinden. Der Verband bietet regelmäßig Trainerlehrgänge in unterschiedlichen Ausbildungsstufen an, damit die Kids pädagogisch fundiert betreut werden.'
                }, {
                    question: 'Und welche Kosten kommen da alles in allem auf uns zu?',
                    answer: 'Ihr müsst mit einer Aufnahme- und Jahresgebühr in eurem Landesrollsportverband rechnen. Hinzu kommen eine Startgebühr für den Ligabetrieb, Kosten für die Ausbildung von Zeitnehmer:innen, ggfs. Mieten für die Heimspielstätte, Gebühren für Schiedsrichter:innen bei Heimspielen und Fahrtkosten bei Auswärtsspielen. Sofern Eure Spieler:innen für die individuelle Ausrüstung inkl. Trikots aufkommen, sollten ihr für eine Saison in der untersten Liga ca. €4.000,- bis €5.000,- rechnen. Euer:eure Landesfachwart:in berät euch gerne zu möglichen Zuschüssen.'
                }, {
                    question: 'Geht das Ganze noch leichter und billiger?',
                    answer: 'Ja, einige Landesverbände - z.B. Bayern - bieten sogenannte "Rookie-Ligen" an. Das sind vereinfachte Turnierrunden für Einsteigerteams, die noch keinem Verein angehören. Schiedsrichter:innen und Zeitnehmer:innen stellt der Verband; gespielt wird mit Personalausweisen. Es fällt lediglich eine geringe Startgebühr an. Dies ist eine wunderbare Möglichkeit, um für sich herauszufinden, ob das Abenteuer Skaterhockey weitergehen soll.'
                }]}/>
            </Block>

            <SocialMediaBlock/>

            <ImpressionBlock alt="Impressionen Teams & Vereine ">
                <StaticImage src="../images/impressions/herren_schlagschuss.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_jubel_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_zweikampf_aussen_2.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_angriff_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_pokal_jubel.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_angriff_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_torwart_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_jubel_publikum_1.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/shake_hands_1.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_bank_4.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_zweikampf_3.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_sieger.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_bully_6.jpg" height={600} aspectRatio={16 / 9} alt=""/>
                <StaticImage src="../images/impressions/herren_tor_szene_1.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_zweikampf_aussen_3.jpg" height={600} aspectRatio={16 / 9}
                             alt=""/>
                <StaticImage src="../images/impressions/herren_bank_2.jpg" height={600} aspectRatio={16 / 9} alt=""/>
            </ImpressionBlock>
        </>
    );
};

export default ClubsPage
