import React from 'react';
import {Card} from 'react-bootstrap';
import PropTypes from 'prop-types';
import Mailto from './Mailto';

const RegionalCard = ({title, name, email, website}) => {
    return (
        <Card className="h-100">
            <Card.Body>
                <Card.Title>{title}</Card.Title>
                <Card.Subtitle className="text-muted mb-2">{name}</Card.Subtitle>
                {email && (
                    <Mailto email={email} as={Card.Link}>Kontakt</Mailto>
                )}
                {website && (
                    <Card.Link href={website} target="_blank" rel="noreferrer">Webseite</Card.Link>
                )}
            </Card.Body>
        </Card>
    )
};

RegionalCard.propTypes = {
    title: PropTypes.node.isRequired,
    name: PropTypes.node.isRequired,
    email: PropTypes.string,
    website: PropTypes.string,
};

export default RegionalCard;
